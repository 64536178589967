
const objAukin = [
    {name:"1_kiñe.mp3", path:require("../rakin_aukin/1_kiñe.mp3")},
    {name: "2_epu.mp3",path:require("../rakin_aukin/2_epu.mp3")},
    {name:"3_kvla.mp3",path:require("../rakin_aukin/3_kvla.mp3")},
    {name:"4_meli.mp3",path:require("../rakin_aukin/4_meli.mp3")},
    {name:"5_kechu.mp3",path:require("../rakin_aukin/5_kechu.mp3")},
    {name:"6_kayu.mp3",path:require("../rakin_aukin/6_kayu.mp3")},
    {name:"7_reqle.mp3",path:require("../rakin_aukin/7_reqle.mp3")},
    {name:"8_pura.mp3",path:require("../rakin_aukin/8_pura.mp3")},
    {name:"9_aylla.mp3",path:require("../rakin_aukin/9_aylla.mp3")},
    {name:"10_mari.mp3",path:require("../rakin_aukin/10_mari.mp3")},
    {name:"100_pataka.mp3",path:require("../rakin_aukin/100_pataka.mp3")},
    {name:"1000_waragka.mp3",path:require("../rakin_aukin/1000_waragka.mp3")}
];

const images = [{path:require("../images/wvnenazentu512x512.png")}]




export  {objAukin,images};


